/*=========================================================================================
  File Name: moduleSettingMutations.js
  Description: Setting Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Settings.unshift(item)
  },
  SET_Setting(state, Settings) {
    state.Settings = Settings
  },
  UPDATE_Setting(state, Setting) {
    const SettingIndex = state.Settings.findIndex((p) => p.ID == Setting.ID)
    Object.assign(state.Settings[SettingIndex], Setting)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Settings.findIndex((p) => p.ID == itemId)
    state.Settings.splice(ItemIndex, 1)
},
}
