/*=========================================================================================
  File Name: moduleSettingActions.js
  Description: Setting Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddSetting({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Setting/AddSetting", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllSettings({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/Setting/GetAllSettings")
        .then((response) => {
          commit('SET_Setting', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateSetting({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/Setting/UpdateSetting", item)
        .then((response) => {
          commit('UPDATE_Setting', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetSetting({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/Setting/GetSetting", itemid)
        .then((response) => {
          commit('UPDATE_Setting', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteSetting({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/Setting/DeleteSetting?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
